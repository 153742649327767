define('common/components/comet/behaviors/flyout-menu',[], function () {
    "use strict";

    return Mn.Behavior.extend({
        "$activeFlyoutMenuTarget": null,

        "events": {
            "click @ui.cometFlyoutTrigger": "onClickFlyoutTrigger",
            "keyup": "escapeKeyUp",
            "click @ui.cometFlyoutCloseButton": "closeButtonClick",
            "blur @ui.cometFlyoutCloseButton": "setFocusOnFirstLink"
        },

        "ui": {
            "cometFlyoutTrigger": ".js-comet-flyout-trigger",
            "cometFlyoutCloseButton": ".js-comet-flyout__close-button",
        },

        "initialize": function () {
            this.onClickBody = _.bind(this.onClickBody, this);
            jQuery("body").on("click.comet-fm", this.onClickBody);
        },

        "onClickBody": function(e) {
            var $eTarget = $(e.target);

            if (!this.isFlyoutMenuTrigger($eTarget)) {
                this.checkTargetClose();
            }
        },

        "onClickFlyoutTrigger": function (event) {
            var $target = $(event.currentTarget);
            this.toggleFlyout($target);
        },

        "toggleFlyout": function ($target) {
            var isOpen,
                $flyoutMenu;

            isOpen = $target.data("open");

            if (isOpen) {
                this.checkTargetClose($target);
                return;
            }

            if (this.$activeFlyoutMenuTarget
                && this.$activeFlyoutMenuTarget.is(':visible')) {
                this.toggleFlyout(
                    this.$activeFlyoutMenuTarget
                );

                this.$activeFlyoutMenuTarget = null;
            }

            this.$activeFlyoutMenuTarget = $target;

            $target.data("open", true)
                   .addClass("comet-flyout--visible")
                   .attr("aria-expanded", "true");

            $flyoutMenu = this.$(
                $target.data('flyout-menu-selector') || '.comet-flyout'
            );

            $flyoutMenu.slideDown();

            $flyoutMenu.find(".comet-list-group__row-anchor:visible")
                       .first()
                       .focus();

            this.$(".comet-flyout-overlay")
                .fadeIn();
        },

        "escapeKeyUp": function(keyEvent) {
            if (this.$(".comet-flyout-trigger").data("open")) {
                if (keyEvent.which === 27) { // escape key
                    this.checkTargetClose();
                }
            }
        },

        "checkTargetClose": function ($target) {
            var $flyoutMenu;

            $target = $target || this.$activeFlyoutMenuTarget || this.$(".js-comet-flyout-trigger");

            $target.data("open", false)
                   .removeClass("comet-flyout--visible")
                   .attr("aria-expanded", "false");

            $flyoutMenu = this.$(
                $target.data('flyout-menu-selector') || '.comet-flyout'
            );

            $flyoutMenu.slideUp();

            this.$(".comet-flyout-overlay")
                .fadeOut();

            // Only one flyout menu remains open at a time
            // so the one being closed is assumed to
            // be the active one.
            this.$activeFlyoutMenuTarget = null;
        },

        "closeButtonClick": function() {
            this.checkTargetClose(
                this.$activeFlyoutMenuTarget
            );
        },

        "setFocusOnFirstLink": function () {
            if (!this.$activeFlyoutMenuTarget) {
                return;
            }

            this.$activeFlyoutMenuTarget
                .find(".comet-list-group__row-anchor:visible")
                .first()
                .focus();
        },

        "isFlyoutMenuTrigger": function($eTarget) {
            return $eTarget.hasClass("comet-flyout") || $eTarget.closest(".comet-flyout").length > 0 || $eTarget.hasClass("js-comet-flyout-trigger") || $eTarget.closest(".js-comet-flyout-trigger").length > 0;
        },

        "onBeforeDestroy": function () {
            if (this.$activeFlyoutMenuTarget) {
                this.checkTargetClose(
                    this.$activeFlyoutMenuTarget
                );
            }

            jQuery("body").off("click.comet-fm", this.onClickBody);
        }
    });
});

