define('common/components/comet/behaviors/aria-tab',[], function () {
    "use strict";

    return Mn.Behavior.extend({

        "events": {
            "keydown @ui.tab": "keyDownOnTab"
        },

        "ui": {
            "tab": "[role='tab']"
        },

        "keyDownOnTab": function(e){
            var $currentTarget = $(e.currentTarget),
                $tablist = $currentTarget.parents("[role='tablist']"),
                $tabs = $tablist.find("[role='tab']"),
                currentIndex = $tabs.index($currentTarget),
                indexToFocus;

            switch (e.which){

                // space bar select current tab
                case 32:
                    e.preventDefault();
                    $currentTarget.click();
                    break;

                // end key - go to last tab
                case 35:
                    e.preventDefault();
                    indexToFocus = $tabs.length - 1;
                    break;

                // home key - go to first tab
                case 36:
                    e.preventDefault();
                    indexToFocus = 0;
                    break;

                // left arrow key - go to prev tab, or go to last
                case 37:
                    indexToFocus = currentIndex === 0 ? $tabs.length - 1 : currentIndex - 1;
                    break;

                // right arro key - go to next tab, or go to first
                case 39:
                    indexToFocus = currentIndex === $tabs.length - 1 ? 0 : currentIndex + 1;
                    break;
            }

            // if indexToFocus is set, focus on the tab
            if (typeof indexToFocus === "number") {
                $tabs.get(indexToFocus).focus();

            // otherwise (the key pressed is not one from the above switch statement)
            // see if the key pressed matches the first character of a tab and focus on that tab
            } else if (e.key) {
                var stringMatch = _.find($tabs, function(tab){
                    return tab.innerText.trim().charAt(0).toLowerCase() === e.key;
                });
                stringMatch && stringMatch.focus();
            }

        }

    });
});

