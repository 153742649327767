(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["common/error_pages/_no_access.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "type") == "ProductSettingDisabledError") {
output += "\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("common/error_pages/no-access/_disabled_feature.html", false, "common/error_pages/_no_access.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n";
});
}
else {
output += "\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "extra_information_dict")),"video")) {
output += "\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("common/error_pages/no-access/_video.html", false, "common/error_pages/_no_access.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n    ";
output += "\n    ";
});
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "extra_information_dict")),"asset") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "extra_information_dict")),"asset")),"type_id") == "15f1b7db-1b3f-4e30-8dd9-634c54724e6c") {
output += "\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("common/error_pages/no-access/_channel.html", false, "common/error_pages/_no_access.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n    ";
});
}
else {
output += "\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("common/error_pages/_error.html", false, "common/error_pages/_no_access.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
callback(null,t_13);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n    ";
});
}
;
}
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/templates/error_pages/_no_access", function(){});

