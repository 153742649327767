define('common/components/inactivity-modal/views/inactivity-modal',[
    'i18next',
    'common/components/modals/views/modal',
    'njs!common/components/edde/templates/common/includes/_inactivity_modal(params:tmpl=edde/common/includes/_inactivity_modal.html)'
], function (i18next, ModalView, template) {
    'use strict';

    return ModalView.extend({

        "modelEvents": {
            "change": "render"
        },

        "events": {
            "click a[name=focus-window]": "onClickWindow"
        },

        "template": template,

        "showFooter": function () {
            this.$el.parents('.comet-dialog').find('.comet-dialog__button-row').show();
        },

        "hideFooter": function () {
            this.$el.parents('.comet-dialog').find('.comet-dialog__button-row').hide();
        },

        "hideLogin": function() {
            this.$el.parents('.comet-dialog').find('.save-modal').hide();
        },

        "initialize": function() {
            // Override the initial footer/header since it uses model during '_constructor'
            if (this.model.get('isSystemDown')) {
                this.header = {
                    "title": i18next.t("Your connection to the site has been lost.")
                };
            } else {
                this.header = {
                    "title": i18next.t("Your session has expired, %(firstName)s",
                    {
                        "firstName": this.model.get('firstName') || this.model.get('first_name')
                    })
                };
            }

            this.footer = {
                "dismiss": i18next.t("Ignore"),
                "save": i18next.t("Log In")
            };
        },

        "onClickWindow": function () {
            this.trigger('click:focus-window');
        }
    });
});

