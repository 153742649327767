define('common/components/modals/views/modal',[
        'njs!common/templates/modals/_modal',
        'common/components/modals/templates/alert'
    ], function(template, alertTemplate) {
    'use strict';

    return Mn.ItemView.extend({
        "cssClassNames": null,

        "header": {
            "title": false
        },

        "footer": {
            "dismiss": false,
            "save": false
        },

        "template": false,

        "openerElement": null,

        "title": "Discovery Education",

        "constructor": function (opts) {
            Mn.ItemView.prototype.constructor.apply(this, arguments);
            opts = opts || {};
            if (opts.clickedItem) {
                this.openerElement = opts.clickedItem;
            }

            this._constructor(opts);
        },

        "setParentElement": function() {
            this.parent = this.el;
            this.$parent = $(this.parent);
            this.$parent.addClass('player-modal');

            this.$parent.addClass(this.cssClassNames);

            this.$parent.on('click', '[data-trigger="dismiss"]', function (event) {
                event.stopPropagation();
                this.dismiss(event);
            }.bind(this));
            this.$parent.on('click', '[data-trigger="save"]', function (event) {
                event.stopPropagation();
                this.save(event);
            }.bind(this));
            return this;
        },

        "dismiss": function (event) {
            this.$parent.removeClass('active');
            this.openerElement && this.openerElement.focus();
            _.delay(function () {
                this.triggerMethod('before:dismiss', event);
                this.triggerMethod('dismiss', event);
            }.bind(this), 300);
        },

        "focusFirst": function(e, skipDismissButton) {
            var focusableEls = this.$parent.find(":focusable").not('.js-focus-fence');

            if (skipDismissButton && focusableEls.length > 1) {
                // When the modal first loads, avoid highlighting the dismiss button if possible
                focusableEls = focusableEls.filter(':not([data-trigger="dismiss"])');
            }

            // Find first focusable element in modal and apply focus to it
            focusableEls.first().focus();
        },

        "focusLast": function() {
            // Find last focusable element in modal and apply focus to it
            this.$parent.find(":focusable")
                .not('.js-focus-fence')
                .last().focus();
        },

        "save": function (event) {
            this.triggerMethod('before:save', event);
            this.triggerMethod('save', event);
        },

        "_constructor": function(opts) {
            this.footer = _.extend(this.footer, opts.footer);
            this.header = _.extend(this.header, opts.header);
            this.$el.html(template({
                "footer": this.footer,
                "header": this.header
            }));
            this.listenTo(this, {
                "alert": this._onAlert,
                "before:dismiss": this._onBeforeDismiss,
                "before:render": this._onBeforeRender,
                "dismiss": this._onDismiss
            });
            this.setParentElement(this.el)
                .setElement(this.$('.view'));
        },

        "_onAlert": function(alertObj) {
            this.$parent.find('.alerts').html(alertTemplate(_.extend({}, alertObj, {
                "icon": alertObj.type === "success" ? "badge-correct" : "message-" + alertObj.type
            })));
        },

        "_onDismiss": function() {
            this.$parent.find('.js-focus-fence').remove();
            this.$el.remove();
            this.$parent.remove();
            this.$parent.off('click', '[data-trigger="dismiss"]');
            this.$parent.off('click', '[data-trigger="save"]');
            $(document.body).off('keydown.modal', this._onKeydown);
            this.destroy();
        },

        "_onBeforeDismiss": function() {
            this.$parent.removeClass('active');
        },

        "_onBeforeRender": function() {
            $(document.body).append(this.$parent);
            // This (arbitrary) delay waits until the modal is available in the DOM
            _.delay(function() {
                this.$parent.addClass('active');
                this._onReady();
            }.bind(this), 1);
        },

        "_onKeydown": function (e) {
            // Dismiss the modal on Escape
            var keycode = e.which || e.keyCode || 0;
            if (keycode === 27) {
                this.dismiss();
            }
        },

        "_onReady": function() {
            $(document.body).on('keydown.modal', $.proxy(this._onKeydown, this));
            this._trapFocus();
            this.focusFirst({}, true);
        },

        "_trapFocus": function() {
            // Add a "focus fence" around the modal. If one of these elements receives focus,
            // prevent the user from escaping the modal and wrap focus instead.
            $('<div />').attr('tabindex', 0).addClass('js-focus-fence')
                .prependTo(this.$parent)
                .on('focus', $.proxy(this.focusLast, this));

            $('<div />').attr('tabindex', 0).addClass('js-focus-fence')
                .appendTo(this.$parent)
                .on('focus', $.proxy(this.focusFirst, this));
        }

    });

});

