/**
 * This helper is for mounting small Vue components like modals. To mount full pages from
 * platform-pages which need access to initData and pageData, use
 * common/components/vue-wrapper/views/vue-wrapper.js instead.
 */

define('common/components/vue-wrapper/helpers/mount-vue-component',[
    'i18next',
    'vue',
    'i18next-vue'
], function (i18next, Vue, I18NextVue) {
    "use strict";

    /**
     * @param vueComponent - The component to be mounted
     * @param selector - The DOM element or selector that the component should be mounted to
     * @param props - Optional props to pass to the component, including listeners like onClick
     * @param children - Optional children for the component
     * @param setupFn - Optional function for additional pre-mount setup calls like `app.use(YourPluginName)`
     */
    return function(vueComponent, selector, props, children, setupFn) {
        var eddeStaticPath = (window.paths && window.paths.edde_static) || '/static/';
        var appName = vueComponent.name ? vueComponent.name + 'App' : 'MountVueComponentApp';

        var app = Vue.createApp({
            'name': appName,
            'render': function() {
                return Vue.h(
                    vueComponent,
                    props || {},
                    children || void 0
                );
            }
        });

        app.config.globalProperties.$nebulaSVGPath = eddeStaticPath
            + 'node_modules/@discoveryedu/nebula-icons/nebula.svg';

        // If we accidentally use a RouterLink in a Vue app here, it should break and
        // show a <router-link> tag instead of just showing a warning.
        app.component('RouterLink', {
            "render": function() {
                return Vue.h('router-link');
            }
        });

        app.use(I18NextVue, {
            'i18next': i18next
        });

        app.provide('app', app);

        if (setupFn) {
            setupFn(app);
        }

        app.mount(selector);

        return app;
    };
});

