define('common/components/learn-router/dependency-manager',[], function() {
    'use strict';


    return {

        "loadCSS": function(ssArr, appName, baseUrl, CB){
            _.each(document.styleSheets, function(styleSheet){
                var appAttr = styleSheet && styleSheet.ownerNode.attributes.getNamedItem("data-app");
                if (appAttr && appAttr.value.indexOf(appName) !== 0) {
                    styleSheet.ownerNode.remove && styleSheet.ownerNode.remove();
                }
            });

            this.appName = appName;
            this.numSSLoaded = 0;
            this.callback = CB;
            this.loadedCSS = _.pluck(document.styleSheets, "href");
            this.totalSS = ssArr.length;
            ssArr = _.map(ssArr, function(ssPath){
                if (typeof ssPath === "string") {
                    return baseUrl + ssPath;
                } else {
                    ssPath.href = baseUrl + ssPath.href;
                    return ssPath;
                }
            });
            _.each(ssArr, _.bind(this.loadSingleStyleSheet, this));
        },

        "loadSingleStyleSheet": function(href){
            var ss = document.createElement("link"),
                scriptsInHead = document.querySelectorAll('head script'),
                ref = scriptsInHead[scriptsInHead.length - 1];

            ss.rel = "stylesheet";
            // temporarily set media to something inapplicable to ensure it'll fetch without blocking render
            ss.media = "only x";

            if (typeof href === "string") {
                ss.href = href;
                ss.dataset.app = this.appName;
            } else {
                ss.href = href.href;
                ss.dataset.app = href.appNameSpace;
            }

            if (_.contains(this.loadedCSS, ss.href)){
                // this file is already in the dom and ready to go!
                this.ssLoadComplete();
            } else {
                // Inject link
                    // Note: `insertBefore` is used instead of `appendChild`, for safety re: http://www.paulirish.com/2011/surefire-dom-element-insertion/
                this.ready(function(){
                    ref.parentNode.insertBefore(ss, ref);
                });

                // once loaded, set link's media back to `all` so that the stylesheet applies once it loads
                if (ss.addEventListener) {
                    $(ss).on("load", _.bind(this.loadCB, this));
                }
                ss.onloadcssdefined = this.onloadcssdefined;
                this.onloadcssdefined(_.bind(this.loadCB, this), ss);
            }
            return ss;

        },

        "ready": function(cb){
            if (document.body){
                return cb();
            }
            setTimeout(function(){
                this.ready(cb);
            });
        },

        "loadCB": function(ss){
            if (ss.addEventListener){
                $(ss).off("load");
            }
            ss.media = "all";
            this.ssLoadComplete();
        },

        "onloadcssdefined": function(cb, ss){
            if (_.findWhere(document.styleSheets, {"href": ss.href})){
              return cb(ss);
            }
            setTimeout(_.bind(function() {
                this.onloadcssdefined(cb, ss);
            }, this));
        },

        "ssLoadComplete": function(){
            this.numSSLoaded++;
            if (this.numSSLoaded === this.totalSS) {
              this.onComplete();
            }
        },

        "onComplete": function(){
            this.callback();
        }

    };

});

