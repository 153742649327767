(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/common/includes/_inactivity_modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<p class=\"comet-dialog__content\">\n    ";
if(runtime.contextOrFrameLookup(context, frame, "isSystemDown")) {
output += "\n        ";
output += runtime.suppressValue((lineno = 2, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["We have detected that your connection to the server has been dropped. Please check your connection and try again. If after trying your connection, you still cannot reach the server, the site may be down."])), env.opts.autoescape);
output += "\n    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "isLTIUser") || runtime.contextOrFrameLookup(context, frame, "popupBlocked")) {
output += "\n        ";
if(runtime.contextOrFrameLookup(context, frame, "popupBlocked")) {
output += "\n            ";
output += runtime.suppressValue((lineno = 5, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Your login window was blocked from opening."])), env.opts.autoescape);
output += "<b/>\n        ";
;
}
output += "\n        ";
output += runtime.suppressValue((lineno = 7, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["You will not be able to submit any of your data at this moment. You will need to relogin via a separate window to continue."])), env.opts.autoescape);
output += "\n    ";
;
}
else {
output += "\n        ";
if(runtime.contextOrFrameLookup(context, frame, "isLoginPrompted")) {
output += "\n            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 10, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Please complete the sign in steps in the <a name=\"focus-window\">window</a> that has opened."]))), env.opts.autoescape);
output += "\n        ";
;
}
else {
output += "\n            ";
output += runtime.suppressValue((lineno = 12, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["You will need to log in again to continue your progress. Please select 'Log In' below. Any unsaved work will be lost if the login is not completed before submitting any work."])), env.opts.autoescape);
output += "\n            <br>\n            ";
output += runtime.suppressValue((lineno = 14, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["You will be redirected back to this tab once you have completed logging in."])), env.opts.autoescape);
output += "\n        ";
;
}
output += "\n    ";
;
}
;
}
output += "\n</p>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/common/includes/_inactivity_modal", function(){});

