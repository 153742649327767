define('common/components/comet/behaviors/comet-dropdown',[], function () {
    "use strict";

    return Mn.Behavior.extend({
        "events": {
            "click @ui.toggleDropdown": "onClickToggleDropdown",
            "touchend @ui.toggleDropdown": "onClickToggleDropdown",

            "click @ui.closeDropdown": "onClickCloseDropdown",
            "touchend @ui.closeDropdown": "onClickCloseDropdown",

            "keydown .js-comet-dropdown-trigger[aria-expanded='true']": "checkArrowKey",
            "keydown .comet-dropdown-menu :focusable": "checkTabOrArrowKey"
        },

        "ui": {
            "toggleDropdown": ".js-comet-dropdown-trigger",
            "closeDropdown": ".js-comet-dropdown-trigger-close"
        },

        "initialize": function () {
            this.onClickBody = _.bind(this.onClickBody, this);
            $('body').on('click.comet-dd', this.onClickBody);

            $('body').on('keydown.comet-dd', _.bind(function(e) {
                e.keyCode === 27 && this.closeDropdown(this.$('.js-comet-dropdown-trigger'));
            }, this));
        },

        "onClickBody": function (event) {
            // dont close dropdown if user clicked on child of the dropdown
            // or clicked inside a modal
            if ($(event.target).parents(".comet-dropdown-menu__stay-open-on-click-child").length ||
                $(event.target).parents(".modal-container").length ||
                $(event.target).parents(".comet-modal-overlay--takeover").length){
                return;
            }
            // DO NOT try to close the drop-down
            // if we just toggled it.
            if (this._toggleDropdown) {
                this._toggleDropdown = false;
                return;
            }

            this.closeDropdown(
                this.$('.js-comet-dropdown-trigger')
            );
        },

        "onClickToggleDropdown": function (event) {
            if (this._toggleDropdown) {
                return;
            }

            var $target = $(event.currentTarget);

            this._toggleDropdown = true;

            this.closeDropdown(
                this.$('.js-comet-dropdown-trigger').not($target)
            );

            this.toggleDropdown($target);
        },

        "onClickCloseDropdown": function (event) {
            var $target = $(event.currentTarget),
                $btnDropdown;

            $btnDropdown = $target.closest('.comet-dropdown-menu')
                                  .parent()
                                  .find('.js-comet-dropdown-trigger');

            this.closeDropdown($btnDropdown);
        },

        "toggleDropdown": function ($target) {
            var isOpen = !!$target.data("open");

            if (isOpen) {
                this.closeDropdown($target);
                return;
            }

            this.openDropdown($target);
        },

        "openDropdown": function ($target) {
            var $menu;

            if ($target.parent().hasClass('comet-button-group')) {
                $menu = $target.parent().parent().find('.comet-dropdown-menu');
            } else {
                $menu = $target.parent().find('.comet-dropdown-menu');
            }

            $target.trigger('comet-dd:before:open');

            $target.data("open", true)
                   .attr("aria-expanded", true)
                   .addClass(
                        [
                            "comet-dropdown-trigger--menu-visible",
                            "comet-button--active",
                            "comet-button--hover"
                        ].join(' ')
                    );

            $menu.attr("aria-hidden", false)
                 .addClass("visible");

            $target.trigger('comet-dd:open');
        },

        "closeDropdown": function ($target) {
            var $menu;

            if ($target.parent().hasClass('comet-button-group')) {
                $menu = $target.parent().parent().find('.comet-dropdown-menu');
            } else {
                $menu = $target.parent().find('.comet-dropdown-menu');
            }

            $target.trigger('comet-dd:before:close');

            $target.data("open", false)
                   .attr("aria-expanded", false)
                   .removeClass(
                        [
                            "comet-dropdown-trigger--menu-visible",
                            "comet-button--active",
                            "comet-button--hover"
                        ].join(' ')
                    );

            $menu.attr("aria-hidden", true)
                 .removeClass("visible");

            $target.trigger('comet-dd:close');
        },

        "checkArrowKey": function(e){
            //up arrow pressed
            if (e.keyCode === 38) {
                e.preventDefault();
                this.selectNextUp(e);

            //down arrow pressed
            } else if (e.keyCode === 40) {
                e.preventDefault();
                this.selectNextDown(e);
            }
        },

        "checkTabOrArrowKey": function(e){
            this.checkArrowKey(e);

            // tab key, close if exiting menu
            if (e.keyCode === 9) {

                var $currentTarget = $(e.currentTarget),
                    $dropdown = $currentTarget.parents(".comet-dropdown-menu"),
                    $focusable = $dropdown.find(":focusable");

                if (e.shiftKey) {
                    $focusable.index($currentTarget) === 0 &&
                        this.closeDropdown(this.$('.js-comet-dropdown-trigger'));
                } else {
                    $focusable.index($currentTarget) === $focusable.length - 1 &&
                        this.closeDropdown(this.$('.js-comet-dropdown-trigger'));
                }

            }
        },

        "selectNextUp": function(e){
            var $selected = this.getAndUnselectSelectedItem(),
                $dropdown = $selected.parents(".comet-dropdown-menu"),
                $focusable = $dropdown.find(":focusable"),
                currentIndex = $focusable.index($selected);
            $selected.length && this.selectElementAndReturnExists($focusable.eq(currentIndex - 1)) || this.selectLast(this.getAssociatedDropDown($(e.currentTarget)));
        },

        "selectNextDown": function(e){
            var $selected = this.getAndUnselectSelectedItem(),
                $dropdown = $selected.parents(".comet-dropdown-menu"),
                $focusable = $dropdown.find(":focusable"),
                currentIndex = $focusable.index($selected);
            $selected.length && this.selectElementAndReturnExists($focusable.eq(currentIndex + 1)) || this.selectFirst(this.getAssociatedDropDown($(e.currentTarget)));
        },

        "selectElementAndReturnExists": function($el){
            if ($el.length) {
                $el.get(0).focus();
                return true;
            }
            return false;
        },

        "selectLast": function($parent){
            var $focusable = $parent.find(":focusable");
            $focusable.length && $focusable.last().get(0).focus();
        },

        "selectFirst": function($parent){
            var $focusable = $parent.find(":focusable");
            $focusable.length && $focusable.first().get(0).focus();
        },

        "getAndUnselectSelectedItem": function(parent){
            var $selected = this.$(":focus");
            $selected.blur();
            return $selected;
        },

        "getAssociatedDropDown": function($el){
            var $parent = $el.parents(".comet-dropdown-menu");
            if ($parent.length) {
                return $parent;
            }
            return $el.siblings(".comet-dropdown-menu");
        },

        "onBeforeDestroy": function () {
            $('body').off('click.comet-dd');
            $('body').off('keydown.comet-dd');
        }
    });
});

