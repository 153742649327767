require([
    'app',
    'vue',
    'common/components/learn-router/application',
    'common/components/learn-router/router',
    'common/components/nunjucks-config/main'
], function(app, Vue, App, AppRouter, nunjucksConfig) {
    'use strict';
    window.Vue = Vue;

    var start = function(messages) {

        var application = new App(),
        appRouter = new AppRouter({
            "controller": application
        });

        application.on('start', function() {
            Backbone.history.start({
                "pushState": true,
                "hashChange": false
            });
        });

        application.start();
    },
    locale = app.initData.get("locale");

    if (locale && locale !== "en") {
        var jsonUrl;

        jsonUrl = app.get_path('edde_static', 'common/components/nunjucks-config/localization/' + locale.toLowerCase() + '.json');

        $.getJSON(jsonUrl, function(messages) {
            nunjucksConfig.init(start, messages, locale);
        });
    } else {
        nunjucksConfig.init(start);
    }

});

define("common/components/learn-router/main.js", function(){});

