define('common/components/product-bar/views/product-bar-light',[
    'app',
    'i18next',
    'vue',
    'common/components/vue-wrapper/helpers/mount-vue-component',
    '@discoveryedu/nebula-components',
    'common/components/product-bar/behaviors/quicklist',
    'common/components/product-bar/behaviors/autosuggest'
], function (
    app,
    i18next,
    Vue,
    mountVueComponent,
    NebulaComponents,
    QuicklistBehavior,
    AutosuggestBehavior
) {
    "use strict";

    return Mn.LayoutView.extend({

        "searchSuggestions": null,

        "channels": {
            "learnRouter": Backbone.Radio.channel('learn-router')
        },

        "behaviors": {
            "Quicklist": {
                "behaviorClass": QuicklistBehavior
            },
            "AutoSuggest": {
                "behaviorClass": AutosuggestBehavior
            }
        },

        "quicklistData": null,

        "initialize": function() {
            this.quicklistData = Vue.reactive({
                "quicklist": [], // loaded QL items
                "quicklistIds": [], // all QL ids
                "channelIds": [] // subscribed channel ids
            });
            this.searchSuggestions = Vue.reactive({
                "data": [],
                "user_suggestions": []
            });

            // Listen for subscribed channel ids, whether they are already loaded,
            // will load, or will update
            this.quicklistData.channelIds = this.getFirstKeysFromChannel(Backbone.Radio.channel('subscribedChannels').request('assets'));

            this.listenTo(Backbone.Radio.channel('subscribedChannels'), 'loaded', _.bind(function() {
                this.quicklistData.channelIds = this.getFirstKeysFromChannel(Backbone.Radio.channel('subscribedChannels').request('assets'));
            }, this));

            this.listenTo(Backbone.Radio.channel('subscribedChannels'), 'change', _.bind(function() {
                this.quicklistData.channelIds = this.getFirstKeysFromChannel(Backbone.Radio.channel('subscribedChannels').request('assets'));
            }, this));

            // The quickmenu:add channel loads an array of _all_ QL asset ids.
            // If they are already loaded, we can pass them in immediately; otherwise,
            // listen for them to be loaded and then set them
            if (Backbone.Radio.channel('quickmenu:add').request('assets') && Backbone.Radio.channel('quickmenu:add').request('assets').length) {
                this.quicklistData.quicklistIds = Backbone.Radio.channel('quickmenu:add').request('assets');
            }

            this.listenTo(Backbone.Radio.channel('quickmenu:add'), 'loaded', _.bind(function() {
                this.quicklistData.quicklistIds = Backbone.Radio.channel('quickmenu:add').request('assets');
            }, this));

            this.listenTo(this, 'qlLoaded', function (quicklist, tokens) {
                // The qlLoaded event contains all the QL assets, where each is a
                // Backbone model. Vue is expecting an array of normal objects
                this.quicklistData.quicklist = _.map(quicklist, function(model) {
                    return model.toJSON();
                });

                if (tokens) {
                    this.tokens = tokens;
                }
            });

            var $embeddedProductBar = $('.embedded-product-bar');

            if ($embeddedProductBar.length) {
                var productBarData = $embeddedProductBar.data(),
                    self = this,
                    action = "/learn/search",
                    searchQuery = productBarData.searchQuery || '';

                if (productBarData.requestEndpoint === "learn_search.search_2023") {
                    action = "/learn/search-2023";
                }
                this.vueInstance = mountVueComponent(
                    NebulaComponents.NebulaProductBar,
                    '.embedded-product-bar',
                    {
                        "embed": "true"
                    },
                    {
                        "search": function() {
                            return Vue.h(NebulaComponents.NebulaProductBarSearch, {
                                "action": action,
                                "additionalParams": productBarData.additionalParams,
                                "homeLink": "/learn/search/embedded",
                                "langCode": app.initData.get("locale"),
                                "searchInputId": "nebula-product-bar__search-input",
                                "suggestions": self.searchSuggestions.data,
                                "typeList": app.initData.get("suggestion_types") || null,
                                "userSuggestions": self.searchSuggestions.user_suggestions,
                                "onClearSearchBox": function() {
                                    searchQuery = '';
                                }
                            }, {
                                "searchInput": function(searchInputSlotProps) {
                                    return Vue.h(NebulaComponents.NebulaInput, {
                                        // Attributes
                                        "class": "nebula-product-bar__search-input",
                                        "autocomplete": "off",
                                        "id": "nebula-product-bar__search-input",
                                        "placeholder": i18next.t("Search"),

                                        // Props
                                        "maxlength": 200,
                                        "name": "q",
                                        "size": "s",
                                        "text": searchQuery,

                                        // Listeners
                                        "onKeydown": function(e) {
                                            searchInputSlotProps.emitInputKeyDownEvents(e);
                                        },
                                        "onInput": function(value) {
                                            searchQuery = value;
                                        },
                                        "onKeyup": _.debounce(function(e) {
                                            // this is kind of weird to send the search suggestions into the behavior
                                            // function to get updated but it works, and I didnt know what else to do
                                            // the view and behavior function dont have the same varible scope
                                            self.triggerMethod('updateSearchSuggestions', e, self.searchSuggestions);
                                        }, 250)
                                    });
                                }
                            });
                        }
                    }
                );
            }
        },

        "getFirstKeysFromChannel": function(channel) {
            if (!channel) {
                return [];
            }

            return channel.map(function(channelObj) {
                return Object.keys(channelObj)[0];
            });
        }
    });
});

