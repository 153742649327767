(function (root) {
    "use strict";

    /*
        Syntax: "<build-task-name>": <path-override>
            <build-task-name> => Corresponds to a task name under build.json


            <path-override> => The syntax for these is as follows:

                1. "path" [STRING]
                        The most common use-case. The string path
                        is the path to the edde templates for the
                        task.

                2. false [BOOLEAN]
                        Skips/disables the nunjucks config for the
                        task. Valid value can only be `false`.
                        Setting to `null` or other falsy values
                        will default to the app-name for the path.

                3. { ... } [OBJECT]
                        Define the full configuration object as follows:
                            {
                                "build": true,                // default: true | Set to `false` to skip/disable the nunjucks task
                                "edde_path": "<string-path>", // path to edde templates for this app
                                "dest_path": "<string-path>"  // destination path for the compiled templates
                            }

    */
    var nunjucks_path_overrides = {
        "admin": "../../admin/templates/admin/main",
        "admin-reports": "../../admin/templates/reports",
        "assessment-builder": "assessment_builder",
        "assessment-manager": "assessment_manager",
        "assessment-results": "assessment_results",
        "assessments": "common/assessments",
        "assessments-2017": "common/assessments-2017",
        "asset-player": "asset_player",
        "asset-collection-series": "asset_collection_series",
        "check-requirements": "check_requirements",
        "common": false,
        "dashboards": "common/dashboards",
        "edde": "../../edde/templates/edde",
        "item-builder": "item_builder",
        "product-bar": "common/product-bar",
        "quick-menu": "common/quick_menu",
        "tools": "common/tools",
        "ms-teams": "ms_teams"
    };

    if (typeof define === 'function' && define.amd) {
        define('nunjucks-path-overrides',[], function () {
            return nunjucks_path_overrides;
        });
    }

    if (typeof module === 'object' && typeof module.exports === 'object') {
        module.exports = nunjucks_path_overrides;
    }
})(this);

