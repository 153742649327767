/*
 * NJS template loader.
 * Created: 2016-02-04
 * Discovery Education Inc.
 *
 * Syntax:
 *   Without params
 *       njs!<app-path>/<app-name>/templates/<path-to-template>
 *
 *   With params
 *       njs!<app-path>/<app-name>/templates/<path-to-template>(params:key-1=val-1|key-2=val-2| ... key-N=val-N)
 *
 * Supported params:
 *   - tmpl          => The nunjucks template (ending in .html)
 *   - webLoaderPath => The path the Nunjucks WebLoader should use
 *
*/
define('njs',[], function () {
    "use strict";

    var njsEnv;

    return {
        "init": function(passedEnv) {
            njsEnv = passedEnv;
        },
        "load": function (resourceName, req, onload, config) {
            var self = this,
                paramsPattern = /\(params\:(.*)\)$/,
                params;

            // Process params from the resource name
            params = paramsPattern.exec(resourceName);
            params = params && params[1];
            params = this.processParams(params);

            // Remove any params from the resource name string
            resourceName = resourceName.replace(paramsPattern, '');

            req([
                'nunjucks-path-overrides',
                resourceName // Load the actual pre-compiled template. The cool thing about
                             // this is that the pre-compiled template also gets compiled into
                             // the dist file by the rjs optimizer in the builds.
            ], function (njxPathOverrides) {
                var templatesIndex = resourceName.indexOf('/templates/'),
                    appName = resourceName.substr(0, templatesIndex),
                    templateName = params.tmpl || null, // use a custom template name if specified
                    pathOverride,
                    templatePath,
                    template,
                    settings;

                settings = {
                    "build": true,
                    "edde_path": null
                };

                appName = resourceName.substr(0, templatesIndex)
                                      .replace(/^(apps\/|common\/components\/)/, '');

                // This is what is actually returned through require-js
                template = function (data) {
                    pathOverride = njxPathOverrides[appName] || null;

                    if (pathOverride === false) {
                        return void 0;
                    } else if (!pathOverride) {
                        settings.edde_path = appName;
                    } else if (typeof pathOverride === "object") {
                        settings = pathOverride;
                    } else if (typeof pathOverride === "string") {
                        settings.edde_path = pathOverride;
                    }

                    // Translate a Core app name to an Edde template path
                    templatePath = settings.edde_path +
                                   resourceName.replace(/^.*?\/templates\//, '/');

                    templateName = templateName || self.getTemplateName(templatePath);

                    // If we can't get a template name then we
                    // can't get the template.
                    if (!templateName) {
                        return void 0;
                    }

                    // Get the template environment
                    // Implicitly sets the template environment
                    // if it hasn't been already
                    template.getEnv();

                    return njsEnv.render(templateName, data);
                };

                // Helper to get the environment for the template
                // if anyone who needs to use it.
                template.getEnv = function () {
                    this._env = this._env || njsEnv;
                    return this._env;
                };

                // Helper to retrieve the input params
                template.getParams = function () {
                    return params;
                };

                onload(template);
            });
        },

        "processParams": function (paramsString) {
            var params = {},
                paramsArr;

            if (!paramsString) {
                return params;
            }

            paramsArr = paramsString.split('|');

            paramsArr.forEach(function (param, index) {
                var keyValuePair = param.split('=');
                params[keyValuePair[0]] = keyValuePair[1];
            });

            return params;
        },

        "getTemplateName": function (templatePath) {
            var templates = window && window.nunjucksPrecompiled || {},
                templateName = null;

            if (!templates) {
                return null;
            }

            // Template (HTML) files end in either '.njs.html' ...
            templateName = templatePath + '.njs.html';
            if (templates[templateName]) {
                return templateName;
            }

            // ... or just '.html'
            templateName = templatePath + '.html';
            if (templates[templateName]) {
                return templateName;
            }

            return null;
        }
    };
});

