(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["common/modals/_modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-overlay\" data-trigger=\"dismiss\"></div>\n\n<div class=\"modal-container\">\n\n    <div class=\"comet-dialog\" aria-labelledby=\"dialog_header\" role=\"dialog\">\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "header")) {
output += "\n            <div class=\"comet-dialog__inner comet-long-form-text\">\n                <h3 id=\"dialog_header\" class=\"comet-dialog__header\">";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "header")),"title")) {
output += runtime.suppressValue((lineno = 8, colno = 93, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "header")),"title")])), env.opts.autoescape);
;
}
output += "</h3> ";
output += "\n                <!-- <span class=\"dismiss-modal\" data-trigger=\"dismiss\" tabindex=\"0\">CLOSE?</span> -->\n            </div>\n        ";
;
}
output += "\n\n\n        <div class=\"alerts\"></div>\n\n        <section class=\"view\"></section>\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "footer")) {
output += "\n            <div class=\"comet-modal__footer\">\n                <div class=\"comet-dialog__button-row\">\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"pathway")) {
output += "\n                        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"pathway")),"url"), env.opts.autoescape);
output += "\" class=\"comet-button comet-button--ghost comet-dialog__button comet-dialog__button--pathway js-comet-dialog__button--pathway js-comet-modal__pathway\" data-trigger=\"pathway\" target=\"_blank\">\n                            <span class=\"comet-button__inner\">\n                                <svg class=\"comet-button__icon comet-button__icon--right\" aria-hidden=\"true\" focusable=\"false\">\n                                    <use xlink:href=\"";
output += runtime.suppressValue((lineno = 25, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#upper-right-arrow"])), env.opts.autoescape);
output += "\"></use>\n                                </svg>\n                                <span class=\"comet-button__text\">\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"pathway")),"title"), env.opts.autoescape);
output += "\n                                </span>\n                            </span>\n                        </a>\n                    ";
;
}
output += "\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"dismiss")) {
output += "\n                        <button class=\"dismiss-modal comet-button comet-button--ghost comet-dialog__button comet-dialog__button--cancel js-comet-dialog__button--cancel js-comet-modal__close-button\" data-trigger=\"dismiss\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"dismiss"), env.opts.autoescape);
output += "\n                        </button>\n                    ";
;
}
output += "\n\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"save")) {
output += "\n                        <button class=\"comet-button comet-button--primary comet-dialog__button save-modal\" data-trigger=\"save\" type=\"button\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "footer")),"save"), env.opts.autoescape);
output += "\n                        </button>\n                    ";
;
}
output += "\n                </div>\n            </div>\n        ";
;
}
output += "\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/templates/modals/_modal", function(){});

