define('common/components/comet/behaviors/action-menu',[], function () {
    "use strict";

    return Mn.Behavior.extend({
        "events": {
            "click @ui.toggleDropdown": "onClickToggleDropdown",
            "touchend @ui.toggleDropdown": "onClickToggleDropdown",

            "keydown .comet-embedded_action-bar :focusable": "checkArrowKey"
        },

        "ui": {
            "toggleDropdown": ".js-action-quick-menu"
        },

        "initialize": function () {
            this.onClickBody = _.bind(this.onClickBody, this);
            jQuery("body").on("click.comet-am", this.onClickBody);
        },

        "onClickBody": function (event) {
            var $eTarget = $(event.target);
            // do not close if a child of the dropdown was clicked
            // or clicked inside a modal
            if ($eTarget.parents(".action-menu-list").length ||
                $eTarget.parents(".modal-container").length ||
                $eTarget.hasClass("comet-modal-overlay--takeover") ||
                $eTarget.parents(".comet-modal-overlay--takeover").length){
                return;
            }

            // DO NOT try to close the drop-down
            // if we just toggled it.
            if (this._toggleDropdown) {
                this._toggleDropdown = false;
                return;
            }

            this.closeDropdown(
                this.$(".js-action-quick-menu")
            );
        },

        "onClickToggleDropdown": function (event) {
            if (this._toggleDropdown) {
                return;
            }

            var $target = $(event.currentTarget);
            this._toggleDropdown = true;
            this.toggleDropdown($target);
        },

        "toggleDropdown": function ($target) {
            var isOpen = !!$target.data("open");

            if (isOpen) {
                this.closeDropdown($target);
                return;
            }

            this.openDropdown($target);
        },

        "openDropdown": function ($target) {
            this.closeDropdown();
            var $menu;

            $menu = $target.next(".action-menu-list");

            $target.trigger("comet-am:before:open");

            $target.data("open", true)
                   .attr("aria-expanded", true)
                   .addClass("action-list-visible");

            $menu.attr("aria-hidden", false);

            $target.trigger("comet-am:open");
        },

        "closeDropdown": function ($target) {
            var $menu;

            if (!$target) {
                var $target = this.$(".js-action-quick-menu");
            }

            $menu = $target.parent().find(".action-menu-list");

            $target.trigger("comet-am:before:close");

            $target.data("open", false)
                   .attr("aria-expanded", false)
                   .removeClass("action-list-visible");

            $menu.attr("aria-hidden", true);

            $target.trigger("comet-am:close");
        },

        "checkArrowKey": function(e){
            //up arrow pressed
            if (e.keyCode === 38) {
                e.preventDefault();
                this.selectNextUp(e);

            //down arrow pressed
            } else if (e.keyCode === 40) {
                e.preventDefault();
                this.selectNextDown(e);

            // tab key
            } else if (e.keyCode === 9) {

                var $currentTarget = $(e.currentTarget),
                    $dropdown = $currentTarget.parents(".comet-embedded_action-bar"),
                    $focusable = $dropdown.find(":focusable");

                if (e.shiftKey) {
                    $focusable.index($currentTarget) === 0 &&
                        this.closeDropdown(this.$(".js-action-quick-menu"));
                } else {
                    $focusable.index($currentTarget) === $focusable.length - 1 &&
                        this.closeDropdown(this.$('.js-action-quick-menu'));
                }

            }
        },

        "selectNextUp": function(e){
            var $selected = this.getAndUnselectSelectedItem();
            $selected.length && this.selectElementAndReturnExists($selected.parent().prev()) || this.selectLast(this.getAssociatedDropDown($(e.currentTarget)));
        },

        "selectNextDown": function(e){
            var $selected = this.getAndUnselectSelectedItem();
            $selected.length && this.selectElementAndReturnExists($selected.parent().next()) || this.selectFirst(this.getAssociatedDropDown($(e.currentTarget)));
        },

        "selectElementAndReturnExists": function($el){
            if ($el.length) {
                $el.find(":focusable").get(0).focus();
                return true;
            }
            return false;
        },

        "selectLast": function($parent){
            $parent.find(":focusable").last().get(0).focus();
        },

        "selectFirst": function($parent){
            $parent.find(":focusable").first().get(0).focus();
        },

        "getAndUnselectSelectedItem": function(parent){
            var $selected = this.$(":focus");
            $selected.blur();
            return $selected;
        },

        "getAssociatedDropDown": function($el){
            var $parent = $el.parents(".action-menu-list");
            if ($parent.length) {
                return $parent;
            }
            return $el.siblings(".action-menu-list");
        },

        "onBeforeDestroy": function () {
            jQuery("body").off("click.comet-am", this.onClickBody);
        }
    });
});

